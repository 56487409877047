import React, { Component } from "react";
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";

//Import Pricing
import PricingBox from "./pricing-box";

class Pricing extends Component {
  state = {
    pricings: [
      {
        id: 1,
        title: "3 MESES",
        price: "$900",
        duration: "3 meses",
        features: {
          service: "Servicio Premium",
          bonus: "Bono a 2 nivel",
          billingCycle: "Trimestral",
        },
      },
      {
        id: 2,
        title: "6 MESES",
        price: "$1,500",
        duration: "6 meses",
        features: {
          service: "Servicio Premium",
          bonus: "Bono a 3 nivel",
          billingCycle: "Semestral",
        },
      },
      {
        id: 3,
        title: "12 MESES",
        price: "$3,000",
        duration: "12 meses",
        features: {
          service: "Servicio Premium",
          bonus: "Bono a 4 Nivel",
          billingCycle: "Anual",
        },
      },
    ],
  };

  render() {
    return (
      <React.Fragment>
        <section className="section bg-light" id="pricing">
          <Container>
            {/* Render section title */}
            <SectionTitle
              title="Nuestros Paquetes"
              description="Elige el paquete que mejor se adapte a tus necesidades y disfruta de nuestro servicio premium con beneficios adicionales en función de la duración del servicio."
            />

            <Row className="mt-5">
              {/* Render Pricing Box */}
              {this.state.pricings.map((pricing, key) => (
                <PricingBox key={key} pricing={pricing} />
              ))}
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Pricing;
