import React from "react";
import { useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  FormFeedback,
  Form,
  Input,
} from "reactstrap";
import axios from "axios";
import Swal from "sweetalert2";
import ReactLoading from "react-loading";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Section Title
import SectionTitle from "../common/section-title";

const ContactUs = () => {
  const [cargando, setCargando] = useState(false);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: "",
      email: "",
      subject: "",
      comments: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Por favor, ingresa tu nombre"),
      email: Yup.string().required("Por favor, ingresa tu correo electrónico"),
      comments: Yup.string().required("Por favor, ingresa el asunto"),
    }),
    onSubmit: (values) => {
      setCargando(true);
      console.log("values", values);
      const nombre = values.name;
      const correo = values.email;
      const mensaje = values.comments;
      //Cambiar estos datos para cada pagina y su respectivo contacto
      const nombreProp = "ETERNA IA";
      const correoProp = "omegan@eternaia.com.mx";
      const pagina = "eternaia.com.mx";

      // Crear un objeto con los datos a enviar
      const data = {
        nombre,
        correo,
        mensaje,
        nombreProp,
        correoProp,
        pagina,
      };

      console.log(data);
      // Realizar la solicitud POST utilizando Axios
      axios
        .post("https://taeconta.com/api/public/api/correos/publicos", data)
        .then((response) => {
          console.log("Correo enviado con éxito:", response.data);
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Correo enviado exitosamente",
            showConfirmButton: false,
            timer: 1500,
          });
          setCargando(false);
          // clearState(e)
        })
        .catch((error) => {
          console.error("Error al enviar el correo:", error);
          Swal.fire(
            "Error al enviar el correo",
            error.message + ", " + error.response.data.message,
            "error"
          );
          setCargando(false);
          // clearState(e)
        });
    },
  });

  return (
    <React.Fragment>
      <section className="section " id="contact">
        <Container>
          {/* Render section title */}
          <SectionTitle
            title="Contáctanos"
            description="Descubre una nueva forma de entretenimiento con Eterna IA. Ofrecemos acceso ilimitado a más de 20,000 películas y 3,000 series exclusivas, con estrenos mensuales continuos. Además, participa en nuestro programa de recompensas diarias y gana mientras disfrutas de contenido de calidad. Si estás interesado en nuestros servicios, por favor proporciona los siguientes datos de contacto: Nombre, Domicilio para Envío, WhatsApp, y Correo Gmail. Nos pondremos en contacto contigo lo antes posible."
          />

          <Row>
            <Col lg="4">
              <div className="mt-4 pt-4">
                <p className="mt-4">
                  <span className="h5">Dirección del Consultorio:</span>
                  <br />{" "}
                  <a
                    href="https://maps.app.goo.gl/BwGPZ84shvY8QRwD9"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-muted d-block mt-2"
                  >
                    Av. de las Américas 1254, Country Club, 44610 Guadalajara,
                    Jal.
                  </a>
                </p>
                <p className="mt-4">
                  <span className="h5">Teléfono</span>
                  <br />{" "}
                  <a
                    href="tel:+523324696888"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-muted d-block mt-2"
                  >
                    3324696888
                  </a>
                </p>
                <p className="mt-4">
                  <span className="h5">Correo</span>
                  <br />{" "}
                  <a
                    href="mailto:omegan@eternaia.com.mx"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-muted d-block mt-2"
                  >
                    omegan@eternaia.com.mx
                  </a>
                </p>
              </div>
              <div className="embed-responsive embed-responsive-16by9">
                {/* Mapa de Google */}
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14929.228858879962!2d-103.3728986!3d20.6977458!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428af96ccc0f537%3A0x3d8ee639262dbe40!2sTorre%20Mil%20500!5e0!3m2!1ses-419!2smx!4v1723475455149!5m2!1ses-419!2smx"
                  width="600"
                  height="200"
                  className="embed-responsive-item"
                  style={{ border: "0" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </Col>
            <Col lg="8">
              <div className="custom-form mt-4 pt-4">
                <p id="error-msg"></p>
                <div id="message"></div>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                >
                  <Row>
                    <Col lg="6 mt-2">
                      <Input
                        name="name"
                        className=""
                        placeholder="Tu nombre"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.name || ""}
                        invalid={
                          validation.touched.name && validation.errors.name
                            ? true
                            : false
                        }
                      />
                      {validation.touched.name && validation.errors.name ? (
                        <FormFeedback type="invalid">
                          {validation.errors.name}
                        </FormFeedback>
                      ) : null}
                    </Col>
                    <Col lg="6 mt-2">
                      <Input
                        name="email"
                        className=""
                        placeholder="Tu correo"
                        type="email"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email || ""}
                        invalid={
                          validation.touched.email && validation.errors.email
                            ? true
                            : false
                        }
                      />
                      {validation.touched.email && validation.errors.email ? (
                        <FormFeedback type="invalid">
                          {validation.errors.email}
                        </FormFeedback>
                      ) : null}
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12 mt-2">
                      <Input
                        name="subject"
                        className=""
                        placeholder="Asunto"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.subject || ""}
                        invalid={
                          validation.touched.subject &&
                          validation.errors.subject
                            ? true
                            : false
                        }
                      />
                      {validation.touched.subject &&
                      validation.errors.subject ? (
                        <FormFeedback type="invalid">
                          {validation.errors.subject}
                        </FormFeedback>
                      ) : null}
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12 mt-2">
                      <div className="form-group">
                        <textarea
                          name="comments"
                          id="comments"
                          rows="4"
                          className="form-control"
                          placeholder="Tu mensaje..."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.comments || ""}
                        ></textarea>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12" className="text-end">
                      <Button className="submitBnt btn btn-primary">
                        {!cargando ? (
                          "Enviar mensaje"
                        ) : (
                          <ReactLoading type="cylon" width={50} />
                        )}
                      </Button>
                      <div id="simple-msg"></div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default ContactUs;
