import React, { Component } from "react";
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";

//Import Service Box
import ServiceBox from "./service-box";

class Services extends Component {
  state = {
    services: [
      {
        title: "Acceso a Más de 20,000 Películas",
        icon: "fa fa-film", // Icono de película para el acceso a contenido
        description:
          "Explora un extenso catálogo de más de 20,000 películas, desde clásicos hasta los últimos estrenos, disponibles al instante.",
      },
      {
        title: "Más de 3,000 Series Exclusivas",
        icon: "fa fa-tv", // Icono de TV para las series exclusivas
        description:
          "Disfruta de una variedad de más de 3,000 series de las plataformas más populares como Netflix, Prime, y HBO Max.",
      },
      {
        title: "Estrenos Mensuales Continuos",
        icon: "fa fa-calendar-alt", // Icono de calendario para estrenos
        description:
          "Mantente actualizado con más de 1,000 estrenos nuevos cada mes, asegurando que siempre tengas contenido fresco para ver.",
      },
      {
        title: "Programa de Recompensas Diarias",
        icon: "fa fa-money-bill-wave", // Icono de dinero para recompensas
        description:
          "Gana recompensas diarias al invitar amigos y familiares a unirse a Eterna IA y disfruta de beneficios exclusivos.",
      },
      {
        title: "Soporte al Cliente Dedicado",
        icon: "fa fa-headset", // Icono de ayuda para soporte al cliente
        description:
          "Nuestro equipo de soporte está disponible para ayudarte con cualquier duda o problema, asegurando una experiencia fluida.",
      },
      {
        title: "Interfaz Fácil de Usar",
        icon: "fa fa-desktop", // Icono de navegador para interfaz fácil de usar
        description:
          "Nuestra plataforma tiene un diseño intuitivo y fácil de usar, permitiéndote acceder a todo el contenido sin complicaciones.",
      },
      {
        title: "Opciones de Personalización",
        icon: "fa fa-cogs", // Icono de ajustes para personalización
        description:
          "Personaliza tu experiencia de visualización con opciones adaptadas a tus preferencias y necesidades de contenido.",
      },
      {
        title: "Alta Calidad de Streaming",
        icon: "fa fa-video", // Icono de video para alta calidad de streaming
        description:
          "Disfruta de una calidad de streaming excepcional con opciones en alta definición para una experiencia visual óptima.",
      },
      {
        title: "Actualizaciones y Novedades",
        icon: "fa fa-sync-alt", // Icono de actualización para novedades
        description:
          "Mantente al día con las últimas actualizaciones y novedades en nuestro catálogo para no perderte nada.",
      },
    ],
  };
  render() {
    return (
      <React.Fragment>
        <section className="section bg-light" id="services">
          <Container>
            {/* Render section title */}
            <SectionTitle
              title="Nuestros Servicios"
              description="En Eterna IA, ofrecemos una experiencia de entretenimiento única con acceso a más de 20,000 películas y 3,000 series exclusivas. Disfruta de estrenos continuos y participa en nuestro programa de recompensas diarias. Nuestro enfoque innovador asegura que siempre tengas contenido fresco y oportunidades de ganar mientras compartes con amigos y familiares."
            />

            <Row className="mt-5">
              {/* render service box */}
              {this.state.services.map((service, key) => (
                <ServiceBox
                  key={key}
                  title={service.title}
                  icon={service.icon}
                  description={service.description}
                />
              ))}
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Services;
